import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React from "react";

const Testimonials: React.FC = () => {
    const testimonials = [
        {
            name: "John Smith",
            title: "Homeowner",
            feedback: "Aqua Home Improvements completely transformed our kitchen! The team was professional and the results were beyond our expectations.",
            image: "testimonial-1.png", // Replace with a valid image URL
        },
        {
            name: "Emily Johnson",
            title: "Satisfied Client",
            feedback: "The water filtration system they installed is amazing. We now have clean, great-tasting water right from the tap.",
            image: "user-1.png", // Replace with a valid image URL
        },
        {
            name: "Michael Brown",
            title: "Renovation Enthusiast",
            feedback: "The solar panel integration was seamless and has significantly reduced our energy bills. Highly recommend their services!",
            image: "user-2.png", // Replace with a valid image URL
        },
    ];

    return (
        <section id="testimonials" className="bg-gradient-to-br from-gray-100 to-white py-16">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-800 mb-12">
                    What Our Customers Say
                </h2>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={1}
                    autoplay={{ delay: 5000 }}
                    navigation
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                    }}
                    className="py-6"
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div className="bg-white rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition-shadow">
                                <img
                                    src={testimonial.image}
                                    alt={`${testimonial.name}`}
                                    className="w-24 h-24 mx-auto rounded-full mb-4 object-cover"
                                />
                                <h3 className="text-xl font-semibold text-gray-800 mb-2">{testimonial.name}</h3>
                                <p className="text-sm text-gray-500 mb-4 italic">{testimonial.title}</p>
                                <p className="text-gray-600">"{testimonial.feedback}"</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Testimonials;
