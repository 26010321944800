import React from "react";
import { HashLink } from "react-router-hash-link";
const Navbar: React.FC = () => {
    return (
        <nav className="bg-gray-100 shadow-lg top-0 z-50 sticky">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20 flex justify-between items-center py-4">
                {/* Logo */}
                <a href="/" className="flex items-center">
                    <img
                        src="logo.png" // Replace with your logo file path
                        alt="Aqua Home Improvements"
                        className="inline-block"
                    />

                </a>

                {/* Navigation Links */}
                <ul className="hidden md:flex space-x-6">
                    <li>
                        <HashLink smooth to="/#services" className="text-gray-800 hover:text-green-500 transition font-medium">
                            Services
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="/#about" className="text-gray-800 hover:text-green-500 transition font-medium">
                            About Us
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="/#testimonials" className="text-gray-800 hover:text-green-500 transition font-medium">
                            Testimonials
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="/#contact" className="text-gray-800 hover:text-green-500 transition font-medium">
                            Contact
                        </HashLink>
                    </li>
                </ul>

                {/* Mobile Menu Button */}
                <div className="md:hidden">
                    <button
                        className="text-gray-800 focus:outline-none"
                        aria-label="Toggle Navigation"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
