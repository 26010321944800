import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const HeroSection: React.FC = () => {
    const images = [
        { src: "solar-panel-house-roof.jpg", alt: "Solar Panels on Roof" },
        { src: "sink-water-filter-system.png", alt: "Modern Kitchen Renovation" },
        { src: "filtered-water.png", alt: "Clean Water from Tap" },
        { src: "kitchen-cabinets.png", alt: "Luxury Kitchen Cabinets" },
    ];

    return (
        <section className="relative">
            {/* Full-Width Swiper */}
            <div className="w-full">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000 }}
                    loop={true}
                    className="w-full h-[60vh] md:h-[80vh]"
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={image.src}
                                alt={image.alt}
                                className="w-full h-full object-cover"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Content Below Images */}
            <div className="bg-white text-gray-800 py-12">
                <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20 text-center">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold leading-snug">
                        Transform Your Home <br />
                        <span className="text-green-600 text-4xl">
                            With Aqua Home Improvements
                        </span>
                    </h1>
                    <p className="mt-4 text-base sm:text-lg lg:text-xl text-gray-700">
                        Discover top-tier home renovations, water filtration solutions, and more. 
                        Your dream home starts here!
                    </p>
                    <div className="mt-6 flex flex-col sm:flex-row gap-4 justify-center">
                        <a
                            href="#services"
                            className="bg-green-500 text-white font-semibold py-3 px-6 sm:px-8 rounded-md shadow-lg hover:bg-green-600 transition"
                        >
                            Explore Services
                        </a>
                        <a
                            href="#contact"
                            className="bg-yellow-400 text-gray-900 font-semibold py-3 px-6 sm:px-8 rounded-md shadow-lg hover:bg-yellow-500 transition"
                        >
                            Get a Free Quote
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
