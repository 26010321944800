import React, { useState } from "react";
import filteringSystemsData from "../data/filtering-systems-data";

function FilteringSystems() {
    const [activeTab, setActiveTab] = useState(0);
    const [transitioning, setTransitioning] = useState(false);

    const handleTabClick = (index: number) => {
        setTransitioning(true);
        setTimeout(() => {
            setActiveTab(index);
            setTransitioning(false);
        }, 300); // Duration of the transition
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-10 xl:px-16 py-12">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Filtering Systems</h1>

            {/* Tabs */}
            <div className="flex flex-wrap justify-center gap-2 sm:gap-4 mb-8">
                {filteringSystemsData.map((system, index) => (
                    <button
                        key={index}
                        className={`py-2 px-4 w-full sm:w-auto rounded-lg font-medium text-center transition ${activeTab === index
                                ? "bg-blue-500 text-white shadow-lg"
                                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                            }`}
                        onClick={() => handleTabClick(index)}
                    >
                        {system.title}
                    </button>
                ))}
            </div>

            {/* Content */}
            <div
                className={`transition-opacity duration-300 ${transitioning ? "opacity-0" : "opacity-100"
                    }`}
            >
                {filteringSystemsData[activeTab].content}
            </div>
        </div>
    );
}

export default FilteringSystems;
