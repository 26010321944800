import React from "react";

const CallToActionBanner: React.FC = () => {
    return (
        <section className="bg-gradient-to-r from-green-500 to-blue-500 text-white py-16 min-h-[400px] flex items-center">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20 text-center">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6">
                    Ready to Transform Your Home?
                </h2>
                <p className="text-lg sm:text-xl lg:text-2xl mb-8">
                    Contact our experts today and start your journey toward a better, more efficient home.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    <a
                        href="#contact"
                        className="bg-white text-green-600 font-semibold py-3 px-8 rounded-md shadow-lg hover:bg-gray-100 transition"
                    >
                        Get a Free Quote
                    </a>
                    <a
                        href="#services"
                        className="bg-blue-600 text-white font-semibold py-3 px-8 rounded-md shadow-lg hover:bg-blue-700 transition"
                    >
                        Explore Services
                    </a>
                </div>
            </div>
        </section>
    );
};

export default CallToActionBanner;
