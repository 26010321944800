import { HashLink } from "react-router-hash-link";

const filteringSystemsData = [
    {
        title: "EquinoxPRO",
        image: "equinoxPRO.png", // Replace with the path to the EquinoxPRO image
        content: (
            <div className="bg-gray-100 p-6 rounded-lg">
                <div className="mt-8 text-center mb-5">
                    <HashLink
                        smooth
                        to="/#contact"
                        className="bg-green-500 text-white py-3 px-6 rounded-md shadow-lg font-medium hover:bg-green-600 transition"
                    >
                        Contact Us Now
                    </HashLink>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">EquinoxPRO Filtration Media</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="list-none text-gray-700 space-y-4">
                        <li>
                            <strong>1. Nusoft Media:</strong> Promotes the growth and health of cells and strengthens
                            various organs in our bodies.
                        </li>
                        <li>
                            <strong>2. Coconut Shell Carbon:</strong> Designed for the reduction of taste, odors,
                            dissolved organics, and chemicals.
                        </li>
                        <li>
                            <strong>3. KDF Media:</strong> Removes chlorine, lead, and other heavy metals.
                        </li>
                        <li>
                            <strong>4. Catalytic Carbon:</strong> Reduces taste, odor, chlorine, and chloramine
                            effectively.
                        </li>
                        <li>
                            <strong>5. Gravel:</strong> High-grade sediment reduction that acts like a clarifier and
                            reduces turbidity.
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <img
                            src="equinoxPRO.png" // Replace with the actual path to the uploaded image
                            alt="EquinoxPRO System"
                            className="rounded-lg shadow-lg max-h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: "OdysseyPRO",
        image: "odysseyPRO.png", // Replace with the path to the OdysseyPRO image
        content: (
            <div className="bg-gray-100 p-6 rounded-lg">
                <div className="mt-8 text-center mb-5">
                    <HashLink
                        smooth
                        to="/#contact"
                        className="bg-green-500 text-white py-3 px-6 rounded-md shadow-lg font-medium hover:bg-green-600 transition"
                    >
                        Contact Us Now
                    </HashLink>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">OdysseyPRO Filtration Media</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="list-none text-gray-700 space-y-4">
                        <li>
                            <strong>1. NuSoft Media:</strong> A catalytic media that prevents the formation of scale and
                            eliminates existing scale by transforming calcium and magnesium minerals into harmless
                            "Nano" crystal particles.
                        </li>
                        <li>
                            <strong>2. KDF Media:</strong> Removes chlorine, lead, and other heavy metals.
                        </li>
                        <li>
                            <strong>3. Catalytic Carbon:</strong> Reduces taste, odor, chlorine, and chloramine
                            effectively.
                        </li>
                        <li>
                            <strong>4. GAC Acid Washed Carbon:</strong> Designed for the reduction of taste, odors,
                            dissolved organics, and chemicals.
                        </li>
                        <li>
                            <strong>5. Gravel:</strong> High-grade sediment reduction that acts like a clarifier and
                            reduces turbidity.
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <img
                            src="odysseyPRO.png" // Replace with the actual path to the uploaded image
                            alt="OdysseyPRO System"
                            className="rounded-lg shadow-lg max-h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: "Stratosphere",
        image: "stratosphere.png", // Replace with the path to the Stratosphere image
        content: (
            <div className="bg-gray-100 p-6 rounded-lg">
                <div className="mt-8 text-center mb-5">
                    <HashLink
                        smooth
                        to="/#contact"
                        className="bg-green-500 text-white py-3 px-6 rounded-md shadow-lg font-medium hover:bg-green-600 transition"
                    >
                        Contact Us Now
                    </HashLink>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Stratosphere Technology</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="list-none text-gray-700 space-y-4">
                        <li>
                            <strong>Catalytic Carbon:</strong> Chemically modified surface enhances catalytic capacity to
                            remove chloramine, the most widely used disinfectant in drinking water.
                        </li>
                        <li>
                            <strong>German Media:</strong> Revolutionary catalyst with a negatively charged surface area
                            for hydrolysis and ion splitting. Filters particles down to &lt; 3 microns.
                        </li>
                        <li>
                            <strong>Alkaline Media:</strong> Includes Far Infrared, ORP, Maifan, and Tourmaline, promoting
                            cell health and detoxification while reducing acidity.
                        </li>
                        <li>
                            <strong>16% Resin:</strong> Efficient chemical and mineral removal with high salt efficiency
                            and economical water use.
                        </li>
                        <li>
                            <strong>Dual Tank Technology:</strong> Uses coconut shell carbon and gravel for advanced
                            sediment filtration.
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <img
                            src="stratosphere.png" // Replace with the actual path to the uploaded image
                            alt="Stratosphere Technology System"
                            className="rounded-lg shadow-lg max-h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: "Galaxis",
        image: "galaxis.png", // Replace with the actual Galaxis image path
        content: (
            <div className="bg-gray-100 p-6 rounded-lg">
                {/* Call-to-Action Button */}
                <div className="mt-8 text-center mb-5">
                    <HashLink
                        smooth
                        to="/#contact"
                        className="bg-green-500 text-white py-3 px-6 rounded-md shadow-lg font-medium hover:bg-green-600 transition"
                    >
                        Contact Us Now
                    </HashLink>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Galaxis Purification Process</h2>
                <p className="text-gray-700 mb-4">
                    The Galaxis system features a 10-stage purification process, designed to provide the ultimate
                    solution for anti-scale and well water, while doubling the capacity of other residential water
                    systems.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="list-none text-gray-700 space-y-4">
                        <li>
                            <strong>1. Coconut Shell Carbon:</strong> Designed for reduction of taste, odors, dissolved
                            organics, and chemicals.
                        </li>
                        <li>
                            <strong>2. Gravel:</strong> High-grade sediment reduction that acts like a clarifier and
                            reduces turbidity.
                        </li>
                        <li>
                            <strong>3. Far-Infrared Rays:</strong> Promote the growth and health of cells, reducing
                            stress and revitalizing various organs.
                        </li>
                        <li>
                            <strong>4. Oxidation Reduction Potential (ORP):</strong> Attacks dangerous free radicals,
                            preventing harm to healthy cells in the body.
                        </li>
                        <li>
                            <strong>5. Tourmaline:</strong> Boosts the immune system and promotes detoxification.
                        </li>
                        <li>
                            <strong>6. Alkaline Ceramic Balls:</strong> Helps the body absorb minerals and fight
                            diseases such as cancer, heart disease, and arthritis.
                        </li>
                        <li>
                            <strong>7. Minerals:</strong> Activates, purifies, and mineralizes drinking water, providing
                            essential nutrients.
                        </li>
                        <li>
                            <strong>8. Catalytic Carbon:</strong> Reduces taste, odor, chlorine, and chloramine.
                        </li>
                        <li>
                            <strong>9. Filtration Process:</strong> Filters particles &lt;3 microns, suspended solids,
                            sediments, turbidity organics, and odors.
                        </li>
                        <li>
                            <strong>10. Removal Process:</strong> Removes iron, manganese, hydrogen sulfide, arsenic,
                            radium, heavy metals, and radionuclides.
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <img
                            src="galaxis.png" // Replace with the actual path to the Galaxis image
                            alt="Galaxis Purification Process"
                            className="rounded-lg shadow-lg max-h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: "Polaris",
        image: "polaris.png", // Replace with the actual Polaris image path
        content: (
            <div className="bg-gray-100 p-6 rounded-lg">
                <div className="mt-8 text-center mb-5">
                    <HashLink
                        smooth
                        to="/#contact"
                        className="bg-green-500 text-white py-3 px-6 rounded-md shadow-lg font-medium hover:bg-green-600 transition"
                    >
                        Contact Us Now
                    </HashLink>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Polaris 10-Stage Dual Technology</h2>
                <p className="text-gray-700 mb-4">
                    The Polaris system incorporates advanced dual-tank technology with a 10-stage purification
                    process, designed for exceptional water filtration and softening.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="list-none text-gray-700 space-y-4">
                        <li>
                            <strong>Catalytic Carbon:</strong> Chemically modified to remove chloramine, the most
                            commonly used disinfectant for drinking water.
                        </li>
                        <li>
                            <strong>Far Infrared:</strong> Promotes the growth and health of cells and strengthens
                            various organs in the body.
                        </li>
                        <li>
                            <strong>ORP (Oxidation Reduction Potential):</strong> Attacks dangerous free radicals,
                            preventing harm to healthy cells.
                        </li>
                        <li>
                            <strong>Maifan:</strong> Removes heavy metals like mercury, lead, cadmium, and arsenic,
                            while releasing essential minerals into the water.
                        </li>
                        <li>
                            <strong>Tourmaline:</strong> Boosts the immune system and promotes detoxification.
                        </li>
                        <li>
                            <strong>Ions Exchange:</strong> Helps reduce bacteria, improving water safety.
                        </li>
                        <li>
                            <strong>Resin 16%:</strong> Ensures exceptional water softening, with high efficiency and
                            cost-effectiveness.
                        </li>
                        <li>
                            <strong>Alkaline Ceramic Balls:</strong> Regulate the acidity of water, creating alkaline
                            water that neutralizes excess acidity in the body.
                        </li>
                        <li>
                            <strong>Principal Applications:</strong> Effective chemical and mineral removal for
                            residential and industrial softening.
                        </li>
                        <li>
                            <strong>Regulatory Approvals:</strong> Certified by NSF for high-quality performance and
                            reliability.
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <img
                            src="polaris.png" // Replace with the actual path to the Polaris image
                            alt="Polaris Purification System"
                            className="rounded-lg shadow-lg max-h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
        ),
    },
];


export default filteringSystemsData;