import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator: React.FC = () => {
    const qrData = "https://aquahomeimprovements.com"; // Replace with your desired data

    return (
        <div className="flex flex-col items-center bg-gray-100 py-10">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
                Aqua Home Improvements
            </h2>
            <div className="bg-white p-4 rounded-lg shadow-lg">
                <QRCodeCanvas
                    value={qrData}
                    size={200} // Size of the QR code
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"H"} // Error correction level (L, M, Q, H)
                />
            </div>
            <p className="text-gray-600 mt-4 text-sm">
                Scan and transform your home today!
            </p>
        </div>
    );
};

export default QRCodeGenerator;
