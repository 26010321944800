import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import Navigationbar from "../components/navigation-bar";
import FilteringSystems from "../pages/filtering-systems";
const MainRouter: React.FC = () => {
    return (
        <Router>
             <Navigationbar />
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/filtering-systems" element={<FilteringSystems />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
};

export default MainRouter;
