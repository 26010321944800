import React from "react";

const AboutUsSection: React.FC = () => {
    return (
        <section id="about" className="bg-gray-50 py-16">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <div className="flex flex-col lg:flex-row items-center gap-12">
                    {/* Left Content: Image */}
                    <div className="w-full lg:w-1/2">
                        <img
                            src="about-us.png" // Replace with a valid image URL
                            alt="About Aqua Home Improvements"
                            className="rounded-lg shadow-lg object-cover w-full h-96"
                        />
                    </div>

                    {/* Right Content: Text */}
                    <div className="w-full lg:w-1/2">
                        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800 mb-6">
                            About Us
                        </h2>
                        <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                            At Aqua Home Improvements, we are dedicated to transforming your home
                            into a space that’s not only beautiful but also functional and
                            energy-efficient. With years of expertise in water filtration systems,
                            solar panel integration, and home renovations, we bring innovation and
                            craftsmanship together to exceed your expectations.
                        </p>
                        <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                            Our mission is to create sustainable, high-quality home solutions that
                            enhance your daily life. From clean water to modern renovations, every
                            project is tailored to your needs and executed with precision.
                        </p>
                        {/* <a
                            href="#contact"
                            className="inline-block bg-green-500 text-white font-semibold py-3 px-8 rounded-md shadow-lg hover:bg-green-600 transition"
                        >
                            Get in Touch
                        </a> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUsSection;
