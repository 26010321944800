import React from 'react';
import HeroSection from './components/hero-section';
import ServiceSection from './components/service-section';
import HowItWorksSection from './components/how-it-works-section';
import Testimonials from './components/testimonials-section';
import CallToActionBanner from './components/call-to-action';
import ContactForm from './components/contact-form-section';
import Footer from './components/footer-section';
import AboutUsSection from './components/about-us-section';
function App() {
  return (
    <main>
      <HeroSection />
      <ServiceSection />
      <HowItWorksSection />
      <Testimonials />
      <CallToActionBanner />
      <AboutUsSection />
      <ContactForm />
      <Footer />
    </main>
  );
}

export default App;
