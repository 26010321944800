import React from "react";

const ServiceSection: React.FC = () => {
    const services = [
        {
            image: "filter-system-home.png", // Replace with your image URL
            title: "Water Filtration Systems",
            description: "Experience clean, pure water with our advanced filtration solutions.",
            link: "/filtering-systems",
        },
        {
            image: "solar-panel-house-roof.jpg", // Replace with your image URL
            title: "Solar Panel Integration",
            description: "Harness solar energy for a greener, more energy-efficient home.",
            link: "#",
        },
        {
            image: "kitchen-cabinets.png", // Replace with your image URL
            title: "Cabinet Installations",
            description: "Enhance your storage with custom cabinet installations.",
            link: "#",
        },
        {
            image: "window-replacement.png", // Replace with your image URL
            title: "Window Replacements",
            description: "Improve energy efficiency with new window installations.",
            link: "#",
        },
    ];

    return (
        <section id="services" className="bg-gradient-to-br from-gray-50 to-gray-100 py-16">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-800 mb-12">
                    Our Services
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
                        >
                            <div className="relative">
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-full h-40 object-cover"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                            </div>
                            <div className="p-6 text-center">
                                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                                    {service.title}
                                </h3>
                                <p className="text-gray-600 mb-4">{service.description}</p>
                                <a
                                    href={service.link}
                                    className="inline-block text-green-500 font-semibold hover:text-green-600 transition"
                                >
                                    Learn More →
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServiceSection;
