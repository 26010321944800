import React, { useState } from "react";
// import { useNavigate } from "react-router-dom"; // For navigation after submission

const ContactForm: React.FC = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);

        // Use fetch API to submit form data to Netlify
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData as any).toString(),
        })
            .then(() => {
                setIsSubmitted(true); // Set submission state
            })
            .catch((error) => alert("There was an error submitting the form. Please try again later."));
    };

    return (
        <section id="contact" className="bg-gray-100 py-16">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-800 mb-12">
                    Get in Touch
                </h2>
                <p className="text-center text-gray-600 mb-8 text-lg">
                    Have questions or ready to start your project? Fill out the form below and we'll get back to you shortly!
                </p>
                <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
                    {!isSubmitted ? (
                        <form
                            name="contact"
                            method="POST"
                            data-netlify-recaptcha="true"
                            data-netlify="true"
                            netlify-honeypot="bot-field"
                            className="space-y-6"
                            onSubmit={handleSubmit}
                        >
                            {/* Hidden input for Netlify */}
                            <input type="hidden" name="form-name" value="contact" />
                            <div hidden>
                                <label>
                                    Don’t fill this out if you're human:
                                    <input name="bot-field" />
                                </label>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                        placeholder="Enter your full name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="subject">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="Enter the subject"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={5}
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="Write your message here"
                                    required
                                ></textarea>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="bg-green-500 text-white font-semibold py-3 px-8 rounded-md shadow-lg hover:bg-green-600 transition"
                                >
                                    Send Message
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="text-center space-y-4">
                            <h3 className="text-2xl font-bold text-green-600">
                                Thank You for Your Submission!
                            </h3>
                            <p className="text-gray-700">
                                We appreciate you reaching out to us. Our team will get back to you as soon as possible.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
