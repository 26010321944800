import React from "react";
import { FaClipboardCheck, FaDraftingCompass, FaTools, FaSmile } from "react-icons/fa";

const HowItWorks: React.FC = () => {
    const steps = [
        {
            icon: <FaClipboardCheck className="text-4xl text-blue-500" />,
            title: "Step 1: Free Consultation",
            description: "Schedule a free consultation to discuss your needs and vision for your home.",
        },
        {
            icon: <FaDraftingCompass className="text-4xl text-green-500" />,
            title: "Step 2: Custom Plan",
            description: "Our experts will create a personalized plan tailored to your goals and budget.",
        },
        {
            icon: <FaTools className="text-4xl text-yellow-500" />,
            title: "Step 3: Professional Installation",
            description: "Our skilled team handles all installations with precision and care.",
        },
        {
            icon: <FaSmile className="text-4xl text-purple-500" />,
            title: "Step 4: Enjoy Your Upgrades",
            description: "Relax and enjoy your newly transformed home with enhanced comfort and style.",
        },
    ];

    return (
        <section className="bg-white py-16">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-800 mb-12">
                    How It Works
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className="bg-gray-100 rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition-shadow"
                        >
                            <div className="flex justify-center mb-4">{step.icon}</div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">{step.title}</h3>
                            <p className="text-gray-600">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
